<!--
 * @Description: 服务单管理日志
 * @Author: ChenXueLin
 * @Date: 2022-02-14 14:11:17
 * @LastEditTime: 2022-08-25 15:30:47
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="headerType">
              <e6-vr-select
                v-model="headerType"
                :data="recordTypeList"
                placeholder="日志类型"
                title="日志类型"
                @change="handleChange(headerType)"
                :is-title="true"
              ></e6-vr-select>
            </el-form-item>

            <el-form-item class="search-item--1" prop="serviceNo">
              <el-input
                v-model="searchForm.serviceNo"
                placeholder="服务单号"
                title="服务单号"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="search-item--1"
              prop="itemId"
              v-if="headerType != 7"
            >
              <el-input
                v-model="searchForm.itemId"
                placeholder="工单ID"
                title="工单ID"
                maxlength="10"
                @input="
                  searchForm.itemId = searchForm.itemId.replace(/[^0-9-]+/, '')
                "
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="operator">
              <el-input
                v-model="searchForm.operator"
                placeholder="操作人"
                title="操作人"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--2" prop="operationTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="操作时间"
                ref="effectiveDatePicker"
                v-model="searchForm.operationTime"
                title="操作时间"
                :picker-options="pickerOptions('searchForm.operationTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="操作时间（始）"
                end-placeholder="操作时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <!-- <i class="e6-icon-history" title="历史记录"></i> -->
          <el-button
            type="primary"
            @click="handleContrast"
            style="margin-right:10px"
            >对比信息</el-button
          >
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          @selection-change="handleSelectionChange"
          :header-cell-class-name="cellClass"
          class="table"
          ref="tableList"
        >
          <el-table-column
            type="selection"
            width="50"
            :selectable="checkSelectable"
          ></el-table-column>
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="column.fieldName === 'attrName'">
                <el-button type="text" @click="toDetail(row)">
                  {{ row[column.fieldName] }}
                </el-button>
              </span>
              <span v-else>{{ row[column.fieldName] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <!--   -->
    <Contrast
      :dialogVisible="dialogVisible"
      :data="contrastData"
      :columnTitle="columnTitle"
      :rowData="rowData"
      @handleClose="handleClose"
    >
    </Contrast>
  </div>
</template>

<script>
// import { printError } from "@/utils/util";
import {
  getServiceTaskHis, //服务单日志分页列表
  getServiceRepairHis, //服务单维修申请任务项日志分页列表
  getServiceSceneChangeHis, //服务单场景变更任务项日志分页列表
  getServiceOpenHis, //服务单开通申请任务项日志分页列表
  getServiceStopHis, //服务单报停申请任务项日志分页列表
  getServicePrintHis, //服务单设置打印信息任务项日志分页列表
  getServiceDockHis, //服务单对接申请任务项日志分页列表
  getServiceReplyHis //服务单咨询回复任务项日志分页列表,
} from "@/api";
import record from "../mixins";
export default {
  name: "serviceTaskRecord",
  data() {
    return {
      searchForm: {
        serviceNo: "",
        itemId: "",
        operator: "",
        operationTime: [],
        pageIndex: 1,
        pageSize: 20
      },
      queryListAPI: getServiceTaskHis, //获取列表的请求名
      recordTypeList: [
        {
          id: 7,
          label: "服务单列表"
        },
        {
          id: 8,
          label: "维修申请的任务项"
        },
        {
          id: 9,
          label: "场景变更任务项"
        },
        {
          id: 10,
          label: "开通申请任务项"
        },
        {
          id: 11,
          label: "报停申请任务项"
        },
        {
          id: 12,
          label: "设置打印信息任务项"
        },
        {
          id: 13,
          label: "对接申请任务项"
        },
        {
          id: 14,
          label: "咨询回复任务项"
        }
      ],
      headerType: 7
    };
  },
  mixins: [record],
  watch: {
    // 创建开始时间
    "searchForm.operationTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.operationTime = this.searchForm.operationTime.reverse();
        }
        this.searchForm.operationStartTime = val[0] || "";
        this.searchForm.operationEndTime = val[1] || "";
      }
    }
  },
  computed: {},
  components: {},
  methods: {
    handleChange(val) {
      switch (val) {
        case 7:
          this.queryListAPI = getServiceTaskHis;
          break;
        case 8:
          this.queryListAPI = getServiceRepairHis;
          break;
        case 9:
          this.queryListAPI = getServiceSceneChangeHis;
          break;
        case 10:
          this.queryListAPI = getServiceOpenHis;
          break;
        case 11:
          this.queryListAPI = getServiceStopHis;
          break;
        case 12:
          this.queryListAPI = getServicePrintHis;
          break;
        case 13:
          this.queryListAPI = getServiceDockHis;
          break;
        case 14:
          this.queryListAPI = getServiceReplyHis;
          break;
      }
      this.searchForm.pageIndex = 1;
      this.getHeadersReq();
      this.getCompareHeaderReq();
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.table {
  .DisableSelection {
    .cell {
      display: none !important;
    }
  }
}
</style>
